export default {
    state: {
        arr: {
            selectedItem: null
        }
    },
    getters:{
        INSTRUCTIONS: state=> {
            return state.arr
        }
    },
    mutations: {
        INSTRUCTIONS: (state,payload) =>{
            state.arr=payload
        },
    },
    actions: {
        INSTRUCTIONS: (context, payload) => {
            context.commit('INSTRUCTIONS',payload)
        },
    },

}