import Vue from 'vue'
import Vuex from 'vuex'
import nav from './nav'
import send from './send'
import instructions from './instructions'

//import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
            Object.assign(state, JSON.parse(sessionStorage.getItem('store')))
        );
      }
    },
  },
  modules: {
    app,send,nav,instructions
  }
})

/*
export default new Vuex.Store({
  state: {
    err: null,
    tab: null,
    tabarr: [
      {caption: 'ГЛАВНАЯ', name: 'Home', route: '/'},
      {caption: 'ОБНОВЛЕНИЯ', name: 'Updates', route: '/updates'}
    ]
  },
  mutations: {
    setErr(state, error){
      state.err=error
    },
    clearErr(state){
      state.err=null
    },
    setTabarr(state, tabarr){
      let add=true
      // найти элемент
      if(Boolean(tabarr.route)==false) {
        tabarr.route = "/" + tabarr.name
      }
      for(let i=0; i<state.tabarr.length; i++){
         if(state.tabarr[i].name==tabarr.name){
           state.tab=i
           add=false
         }
      }
      if(add){
        state.tabarr.push(tabarr)
        state.tab=state.tabarr.length-1
      }
    },
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        // Replace the state object with the stored item
        this.replaceState(
            Object.assign(state, JSON.parse(sessionStorage.getItem('store')))
        );
      }
    },
  },
  getters: {
    getErr: s=>s.err,
    getTab: s=>s.tab,
    getTabarr: s=>s.tabarr
  },
  actions: {
  },
  modules: {
    app,send
  }
})


 */