
//---------------------------------------------------------------------------------------------------
function tel2telto(sTel) {
    let regex = /\d+?/g;

    let result = 'tel:+'
    let match;
    while (match = regex.exec(sTel)) {
        result += match[0]
    }
    return result
}
//---------------------------------------------------------------------------------------------------
function email2mailto(sEmail, sSubject, sBody) {
    if(Boolean(sSubject)==false){
        sSubject='Обращение с сайта  liner74.ru.'
    }
    if(Boolean(sBody)==false){
        sBody='Задайте интересующий вас вопрос и мы с удовольствием вам ответим.'
    }
    return `mailto:${sEmail}?subject=${sSubject}&body=${sBody}`
}

export {tel2telto, email2mailto} // список экспортируемого