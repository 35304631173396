function openNav() {
    document.getElementById("myAppBar").style.position = "static";
    document.getElementById("myNav").style.height = "100%";
    return ''
}
function closeNav() {
    document.getElementById("myNav").style.height = "0%";
    document.getElementById("myAppBar").style.position = "fixed";
    console.log("closeNav")
    return ''
}
async function  navUpdates(_this){
    await _this.$router.push('/updates').then(done=> {
        closeNav()
    }, error => {
        closeNav()
    })
}
async function navSend(_this){
    await _this.$router.push('/send').then(done=> {
        closeNav()
    }, error => {
        closeNav()
    })
    await _this.$router.push(e.route).then(done=> {
        closeNav()
    }, error => {
        closeNav()
    })
}
async function navContacts(_this){
    closeNav()
    try {
        await _this.$router.push('/')
    }catch(e){console.log(e)}
    try {
        await _this.$router.push('/?contacts=1')
    }catch(e){console.log(e)}
    _this.$vuetify.goTo('#contacts', {duration:0,offset:0})

}
async function navMap(_this){
    closeNav()
    try {
        await _this.$router.push('/')
    }catch(e){console.log(e)}
    try {
        await _this.$router.push('/?map=1')
    }catch(e){console.log(e)}
    _this.$vuetify.goTo('#map', {duration:0,offset:0})

}


export {openNav, closeNav, navUpdates, navContacts, navMap, navSend} // список экспортируемого
