<template>

    <!--v-lazy не ставить - не работает переход scroll по кнопки-->
    <section>
        <div class="pa-md-16">

            <SectionHeader :header="header"/>


            <div  class="md-16">
                <v-row>

                    <v-col
                            cols="12"
                            sm="4"
                            v-for="(e,i) in arr"
                            :key="i"
                    >


                        <v-card
                                class="ma-md-5"
                                max-width="700"
                        >
                            <v-img
                                    :src="e.src"
                                    height="300px"
                            ></v-img>

                            <v-card-title>
                                {{e.header}}
                            </v-card-title>

                            <v-card-subtitle class="text-justify text-md-body-1" v-html="e.text"/>

                            <v-divider/>
                            <v-card-text class="text-justify text-md-body-1" v-html="e.fulltext"/>

                            <v-card-actions
                                v-if="e.action.show"
                            >
                                <v-btn
                                        color="orange lighten-2"
                                        text
                                        href="/img/home/server_temp.pdf"
                                        target="_blank"
                                >
                                    Пример счета на сервер
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                </v-row>
            </div>
        </div>
    </section>

</template>

<script>
    import SectionHeader from "./SectionHeader";

    export default {
        name: "SectionCardShow2",
        components: {SectionHeader},
        props: {
            header:  String,
            arr:  Array
        }
    }
</script>

<style scoped>

</style>