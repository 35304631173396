//---------------------------------------------------------------------------------------------------
function phoneCity() {
    return '+7 (351) 231-23-60';
}
//---------------------------------------------------------------------------------------------------
function phoneCell() {
    return '+7 (908) 826-44-23';
}
//---------------------------------------------------------------------------------------------------
function emailLiner() {
    return '312360@mail.ru';
}
//---------------------------------------------------------------------------------------------------
// e -> {caption: 'ГЛАВНАЯ', name: 'Home', route: '/'}
function addNavbarTab(pThis, e) {
    if(Boolean(e)==false){
      e={}
    }
    if(Boolean(e.caption)==false){
      e.caption=pThis.header
    }
    if(Boolean(e.name)==false){
      e.name=pThis.$options.name
    }
    //pThis.$store.commit('SET_NAV', e)
    pThis.$store.commit('SET_NAV', e)
    return null
}

//---------------------------------------------------------------------------------------------------
function siteUrl(port) {
    if(Boolean(port)) {
        port=':'+port
    }else{
        port=''
    }
    return (process.env.NODE_ENV === 'production'?'https://updates.liner74.ru':'https://updates.liner74.ru'+port)
    //return (process.env.NODE_ENV === 'production'?'https://updates.kilseev.ru':'https://updates.kilseev.ru'+port)
    //return (process.env.NODE_ENV === 'production'?'https://updates.kilseev.ru':'http://localhost'+port)
    //return (process.env.NODE_ENV === 'production'?'https://liner74.ru':'http://localhost'+port)
}
//---------------------------------------------------------------------------------------------------
function siteLiner(port) {
    if(Boolean(port)) {
        port=':'+port
    }else{
        port=''
    }
    return (process.env.NODE_ENV === 'production'?'https://liner74.ru':'http://localhost'+port)
}

//---------------------------------------------------------------------------------------------------
function urlUpdates(route) {
  return siteUrl()+':9002/'+route
}

//---------------------------------------------------------------------------------------------------
function hashCode(str) {
    var hash = 0, i = 0, len = str.length
    while ( i < len ) {
        hash  = ((hash << 5) - hash + str.charCodeAt(i++)) << 0
    }
    return hash
}
//---------------------------------------------------------------------------------------------------
function isPswOk(login,psw) {
    let str=login+psw
    let hash=309343759 //admin q..
    let ret=(hash==hashCode(str))
    //console.log(login,psw,hash,'==',hashCode(str),' -> ',ret )
    return (hash==hashCode(str))
}



export {isPswOk, hashCode, siteUrl, siteLiner, phoneCity, phoneCell, emailLiner, addNavbarTab, urlUpdates} // список экспортируемого