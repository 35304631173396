<template>

<nav>
    <v-navigation-drawer
            v-model="drawer"
            temporary
            app
    >
        <v-list
                nav
                app
        >
            <v-list-item-group
                    active-class="blue--text text--accent-4"
                    v-model="selectedItem"
            >
                <!--@click="$router.push(e.route); drawer=false;"   rgb(63,116,168) 56,111,165  50,106,163
                138,184,226
                178,209,238
                -->
                <v-list-item
                        v-for="(e,i) in menu"
                        :key="i"
                        @click="doMenu(i)"
                >
                    <v-list-item-icon>
                        <v-icon>{{e.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{e.title}}</v-list-item-title>
                </v-list-item>
                <!---
                <v-list-item
                        @click="navHome()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconHome}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Главная</v-list-item-title>
                </v-list-item>


                <v-list-item
                        :disabled="!next"
                        @click="navUpdates()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconNotify}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Обновления</v-list-item-title>
                </v-list-item>


                <v-list-item
                        :disabled="!back"
                        @click="navSend()"
                >
                    <v-list-item-icon>
                        <v-icon>{{iconBack}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Обратная связь</v-list-item-title>
                </v-list-item>
-->




            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>

    <!--color="light-blue darken-4"-->

    <!--color="primary"
               dark
     ok          color="rgb(50,106,163)"
    -->

    <!--@click="$router.push(e.route); drawer=false;"   rgb(63,116,168) 56,111,165  50,106,163
    138,184,226
    178,209,238
    -->
    <v-app-bar id="myAppBar"
               app
               :color="color"
               dark
    >
        <template v-slot:extension>
            <v-tabs align-with-title
                    style="text-shadow:   2px 2px 2px rgba(0,0,0,0.4);"
                    v-model="TAB"
                    center-active
                    show-arrows
                    centered
            >
                <!--  @click="$router.push(e.route)"-->

                <v-tab
                        v-for="(e,i) in TABARR"
                        :key="i"
                        @click="tabarrClick(e)"
                >{{e.caption}}</v-tab>
            </v-tabs>
        </template>

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">

                <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="openNav()"
                >
                <!--    @click="drawer = !drawer;" -->
                    <v-icon>
                        {{iconMenu}}
                    </v-icon>
                </v-btn>
            </template>
            <span>Меню</span>
        </v-tooltip>


        <!--@click="$router.push('/'); drawer=false"-->

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title
                        v-bind="attrs"
                        v-on="on"
                        @click="navTop()"
                >
                    <router-link to="/" tag="span" style="cursor: pointer">
                    Liner74.ru
                    </router-link>
                </v-toolbar-title>
            </template>
            <span>Медицинская информационная система &laquo;Лайнер&raquo;</span>
        </v-tooltip>

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       v-bind="attrs"
                       v-on="on"
                       @click="btnLogin()"
                       v-model="$store.getters.NAV_LOGIN"
                >
                    <v-icon>{{ICONLOG}}</v-icon>
                </v-btn>
            </template>
            <span>{{HINTLOG}}</span>
        </v-tooltip>

        <!--v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       v-bind="attrs"
                       v-on="on"
                       @click="chgColor(-1)"
                >
                    <v-icon>{{ileft}}</v-icon>
                </v-btn>
            </template>
            <span>{{color}}</span>
        </v-tooltip>
        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       v-bind="attrs"
                       v-on="on"
                       @click="chgColor(1)"
                >
                    <v-icon>{{iright}}</v-icon>
                </v-btn>
            </template>
            <span>{{color}}</span>
        </v-tooltip-->


        <v-spacer></v-spacer>

        <!--                        @click="$router.push('/'); drawer=false"-->

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title
                        class="hidden-md-and-down pl-16"
                        v-bind="attrs"
                        v-on="on"
                        @click="navMap()"
                >
                    <router-link to="/" tag="span" style="cursor: pointer">
                    Медицинская информационная система &laquo;Лайнер&raquo;
                    </router-link>
                </v-toolbar-title>
            </template>
            <span>Карта сайта</span>
        </v-tooltip>

        <v-spacer></v-spacer>



        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       class="hidden-sm-and-up"
                       v-bind="attrs"
                       v-on="on"
                       :href="TELTO"
                >
                    <v-icon>{{iconPhone}}</v-icon>
                </v-btn>
            </template>
            <span>Позвонить</span>
        </v-tooltip>

        <v-tooltip bottom >
            <template v-slot:activator="{ on, attrs }">

                <v-btn class="hidden-sm-and-down font-weight-bold"
                       v-bind="attrs"
                       v-on="on"
                       plain
                       rounded
                       x-large
                       dark
                       :href="TELTO"
                >
                    <v-icon left>
                        {{iconPhone}}
                    </v-icon>
                    {{tel}}
                </v-btn>
            </template>
            <span>Позвонить</span>
        </v-tooltip>


    </v-app-bar>
</nav>

</template>

<script>

    import {
        mdiPhone,
        mdiChevronLeft,
        mdiChevronRight,
        mdiClose,
        mdiHome,
        mdiApplication,
        mdiMessageAlertOutline,
        mdiMenu,
        mdiBriefcaseDownloadOutline, mdiBriefcaseDownload,
        mdiMapMarkerOutline,
        mdiMapLegend
    } from '@mdi/js';
    import {tel2telto} from "@/components/app/js/tools.js";
    import {phoneCity} from "@/components/app/js/globals.js";
    import {navContacts, navMap, navSend, navUpdates, openNav} from "./js/nav";

    export default {
        name: 'SiteNavbar',
        data: () => ({
            ileft: mdiChevronLeft,
            iright: mdiChevronRight,
            color: 'rgb(50,106,163)',
            curcolor: 0,
            colors: [
                'rgb(63,116,168)',
                'rgb(56,111,165)',
                'rgb(50,106,163)',
                'rgb(138,184,226)',
                'rgb(178,209,238)',
                'rgb(35,89,150)',
                'primary'
            ],
            selectedItem: 1,
            tel: phoneCity(),
            back: true,
            next: true,
            drawer: false,
            iconPhone: mdiPhone,
            iconBack: mdiChevronLeft,
            iconNext: mdiChevronRight,
            iconClose: mdiClose,
            iconHome: mdiHome,
            iconApp: mdiApplication,
            iconNotify: mdiMessageAlertOutline,
            iconMenu: mdiMenu,
            hintLog: 'Вход',
            menu: [
                {
                    title: 'Закрыть меню',
                    icon: mdiClose
                },
                {
                    title: 'Контакты',
                    icon: mdiMapMarkerOutline
                },
                {
                    title: 'Карта сайта',
                    icon: mdiMapLegend
                },
                {
                    title: 'Напишите нам',
                    icon: mdiMessageAlertOutline
                },
                {
                    title: 'Главная',
                    icon: mdiHome
                },
                {
                    title: 'Обновления',
                    icon: mdiBriefcaseDownloadOutline
                },
                {
                    title: "Вход",
                    icon: mdiChevronRight
                }
            ]

        }),
        beforeMount() {
            this.$store.commit('NAV_LOGIN', false)
        },
        computed: {
            TELTO() {
                return tel2telto(this.tel)
            },
            TABARR() {
                return this.$store.getters.NAV
            },
            TAB() {
                return this.$store.getters.NAV_TAB
            },
            HINTLOG() {
                if(this.$store.getters.NAV_LOGIN){
                    return 'Выход'
                }else{
                    return 'Вход'
                }
            },
            ICONLOG() {
                if(this.$store.getters.NAV_LOGIN){
                    return mdiChevronLeft
                }else{
                    return mdiChevronRight
                }
            }


        },
        methods:{
            doMenu(i){
                //console.log(i)
                switch (i) {
                       case 0:
                           break
                       case 1:
                           this.navContacts()
                           break
                       case 2:
                           this.navMap() //UpdatesArc()
                           break
                       case 3:
                           this.navSend()
                           break
                       case 4:
                           this.navHome()
                           break
                       case 5:
                           this.navUpdates()
                           break
                       case 6:
                           this.navLogin()
                           break
                }
                this.drawer = !this.drawer
            },
            async  navHome(){

                this.drawer=false
                try {
                    await this.$router.push('/')
                }catch(e){console.log(e)}
            },
            async navUpdates(){
                await navUpdates(this)
                /*
                //try {
                    await this.$router.push('/updates').then(done=> {
                        //console.log('done')
                        this.drawer=false
                    }, error => {
                        //console.log('error')
                        this.drawer=false
                    })
                //}catch(e){console.log(e)}
                */
            },
            async navUpdatesArc(){
                this.drawer=false
                try{
                    await this.$router.push('/updatesarc')
                }catch(e){console.log(e)}
            },
            async navSend(){
                await navSend(this)
                /*
                await this.$router.push('/send').then(done=> {
                    //console.log('done')
                    this.drawer=false
                }, error => {
                    //console.log('error')
                    this.drawer=false
                })
                await this.$router.push(e.route).then(done=> {
                    //console.log('done')
                    this.drawer=false
                }, error => {
                    //console.log('error')
                    this.drawer=false
                })

                 */
            },
            navLogin() {
                this.$store.commit('NAV_LOGIN', false)
                try {
                    this.$router.push('/login')
                }catch(e){console.log(e)}
            },
            async navContacts(){
                await navContacts(this)
                /*
                //console.log('contacts')
                this.drawer=false
                try {
                    await this.$router.push('/')
                }catch(e){console.log(e)}
                try {
                    await this.$router.push('/?contacts=1')
                }catch(e){console.log(e)}
                this.$vuetify.goTo('#contacts', {duration:0,offset:0})
                */

            },
            async navMap(){
                await navMap(this)
                /*
                //console.log('footer')
                this.drawer=false
                try {
                    await this.$router.push('/')
                }catch(e){console.log(e)}
                try {
                    await this.$router.push('/?map=1')
                }catch(e){console.log(e)}
                this.$vuetify.goTo('#map', {duration:0,offset:0})
                */

            },
            btnLogin() {
                if(!this.$store.getters.NAV_LOGIN) {
                  try {
                      this.$router.push('/login')
                  }catch(e){console.log(e)}
                }else{
                    this.$store.commit('NAV_LOGIN', false)
                }
            },
            async navTop(){
                console.log("top")
                this.$vuetify.goTo('#liner', {duration:0,offset:0})
            },
            chgColor(step){
                this.curcolor=this.curcolor+step
                if(this.curcolor>=this.colors.length){
                    this.curcolor=0
                }
                if(this.curcolor<0){
                    this.curcolor=this.colors.length-1
                }
                this.color=this.colors[this.curcolor]
                //console.log(this.curcolor)
            },
            tabarrClick(e){
                let name=e.name.toLowerCase()
                switch(name){
                    case 'navcontacts':
                        this.navContacts()
                        break;
                    case 'navmap':
                        this.navMap()
                        break;
                    default:
                        this.$router.push(e.route)

                }
            },
            openNav() {
                openNav(this)
            },


        }

    }

</script>
