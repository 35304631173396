<template>
    <section id="instruction">

        <div class="pa-md-16">

            <SectionHeader :header="header"/>



            <div  class="md-16">




                <v-row>

                    <p>Иногда пользователи медицинской информационной системы &laquo;Лайнер&raquo; задают вопросы, на которые проще ответить, имея примеры с &laquo;картинками&raquo;. Обычно такие вопросы возникают по нововведениям вышестоящих министерств, решать которые нужно в спешном порядке всем и сразу.
                    </p>

                    <v-card
                            class="mx-auto"
                            elevation="10"
                            rounded="xl"
                    >
                        <p/>
                        <v-list dense>
                            <v-list-item-title class="title">
                                &nbsp;
                            </v-list-item-title>
                            <!--v-subheader>Иногда пользователи медицинской информационной системы &laquo;Лайнер&raquo; задают вопросы, на которые проще ответить, имея примеры с &laquo;картинками&raquo;. Обычно такие вопросы возникают по нововведениям вышестоящих министерств, решать которые нужно в спешном порядке и всем сразу.</v-subheader-->
                            <v-list-item-group
                                    v-model="selectedItem"
                                    color="primary"
                            >
                                <v-list-item
                                        v-for="(item, i) in items"
                                        :key="i"
                                        @click="doItem(i)"
                                >
                                    <!--v-list-item-icon>
                                        <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon-->
                                    <v-list-item-content>
                                        <v-list-item-title v-text="(i+1)+'. '+item.header"
                                                           class="text-md-body-1"
                                        />
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <p/>
                    </v-card>



                </v-row>
            </div>
        </div>

    </section>

</template>

<script>

    import SectionHeader from "../components/app/SectionHeader";



    export default {
        name: "Instructions",
        data: () => ({
            selectedItem: null,
            header: 'Инструкции',
            items: [
                {name: 'session0'       , max: 8,   header: 'Автоматическое восстановление работы базы данных' },
                {name: 'preverlocal0'   , max: 1,   header: 'Переписать локальную версию программы на сервер'},
                {name: 'viewsessions0'  , max: 1,   header: 'Просмотр текущих блокировок данных'},
                {name: 'uslugi0'        , max: 6,   header: 'Порядок ввода данных по услугам'},
                {name: 'dd'             , max: 10 , header: 'Упрощенный ввод данных по диспансеризации'},
                {name: 'natdel'         , max: 10,  header: 'Как работает сервис "Почистить персонифицированный счет по файлу ошибок фонда (например "n0991 Ошибки.txt'},
                {name: 'ponline'        , max: 6,   header: 'Описание новой возможности получения актуальной информации о полисах и прикреплении в режиме реального времени'},
                {name: 'apostrof'       , max: 6,   header: 'Как поставить "правильные" кавычки в названии ЛПУ?'},
                {name: 'disp'           , max: 11,  header: 'Ввод данных по диспансеризации и медосмотру'},
                {name: 'prof'           , max: 4,   header: 'Как на одного пациента быстро завести данные нескольких талонов к разным специалистам (профосмотр)?'},
                {name: 'xls'            , max: 6,   header: 'Настройка MSExcel для работы старых отчетов'},
                {name: 'talon'          , max: 5,   header: 'Алгоритм выписки талона на сайте talon74.ru'},
                {name: 'talondel'       , max: 2,   header: 'Алгоритм отмены талона на сайте talon74.ru'},
                {name: 'polic'          , max: 4,   header: 'Актуальные полиса на дату посещения'},
                {name: 'ln2_'           , max: 26,  header: 'Работа с листками нетрудоспособности'},
                {name: 'mpic_'          , max: 8,   header: 'Изменения по программе модернизации'},
                {name: 'mailmsg'        , max: 1,   header: 'Рассылка уведомлений о новых обновлениях'},
                {name: 'site'           , max: 2,   header: 'Из программы сразу выйти на нужный сайт программы в интернете'},
                {name: 'lock_'          , max: 3,   header: 'Как найти компьютер, который заблокировал работу?'},
                {name: 'newh'           , max: 3,   header: 'Как правильно внести данные о новорожденном?'},
            ]
        }),
        components: {
            SectionHeader
        },
        beforeMount() {
            this.doRest()
        },
        beforeDestroy() {
            this.doSave()
        },
        methods:{
            async doItem(i){
                try {
                    await this.$router.push('/instruction?header='+this.items[i].header+'&name='+this.items[i].name+'&max='+this.items[i].max)
                }catch(e){console.log(e)}
            },
            doRest() {
                this.selectedItem=this.$store.getters.INSTRUCTIONS.selectedItem
            },
            doSave() {
                let arr= {
                    selectedItem: this.selectedItem
                }
                this.$store.commit('INSTRUCTIONS', arr)
            }

        }

    }
</script>

<style scoped>

</style>