<template>
    <div  class="md-16">
        <v-row>

            <!--:class="isLazy(e)"  чтобы срабатывал goTo-->
            <v-col
                    cols="12"
                    sm="12"
                    max-width="400px"
                    :class="isLazy(e)"
                    v-for="(e,i) in arr"
                    :key="i"
            >


                <v-card
                        class="ma-md-5"
                        :class="cardColor(e.fileExists)"
                        elevation="10"
                        rounded="xl"
                        :id="e.fileName"

                >
                    <!--
                    <v-card-title class="blue-grey darken-1 white--text font-weight-bold text-md-h4"
                                  color="orange lighten-2">

                    -->
                    <v-card-title style="background-color:#326AA3; -background-color:#333366;" class="-grey -darken-2 white--text font-weight-bold text-md-h4"
                                  color="orange lighten-2">
                        {{e.fileName}}
                    </v-card-title>
                    <v-card-subtitle style="background-color:#326AA3; -background-color:#333366;"  class="-grey -darken-2 white--text text-justify" v-html="e.fileDateTime"/>


                    <!--v-divider class="mx-4"></v-divider-->

                    <v-card-text class="pa-md-5 text-md-h6 black--text" v-html="e.html"/>

                    <!--:href="e.fileDownload"-->
                    <!--color="orange lighten-2"-->

                    <v-card-actions class="pa-md-5" v-if="e.fileExists=='1'">
                        <v-btn
                                color="orange lighten-2"
                                text
                                @click="setDownloaded(i)"
                                :href="addDownloadPath(e.fileDownload)"
                                :disabled="downloadDisabled && isDownloaded(i)"
                        >
                            {{downloadCaption(i)}}
                        </v-btn>
                        <v-btn
                                color="orange lighten-2"
                                text
                                @click="sendEmail(i)"
                                :loading="sendLoading && isSending(i)"
                                :disabled="sendDisabled && isSended(i)"
                                v-if="$store.getters.NAV_LOGIN"

                        >
                            {{sendCaption(i)}}
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>

        </v-row>

    </div>


</template>

<script>
    import {urlUpdates} from "./js/globals";
    import post from "./js/post";

    export default {
        name: "UpdateCard",
        props: {
            arr: Array,
            /*{
                        3016: {
                            fileDateTime: "28.12.2020 02:55:38",
                            fileDownload: "/Users/user/Downloads/dev/liner4/src/downloads/update/update3016.exe",
                            fileExists: "0",
                            fileName: "update3016",
                            html: "Персонифицированный счет<br>&nbsp Обновление справочников<br>&nbsp&nbsp&nbspСправочники ФФОМС Справочники ФФОМС"
                        },
                        3017: {
                            fileDateTime: "28.12.2020 02:55:38",
                            fileDownload: "/Users/user/Downloads/dev/liner4/src/downloads/update/update3017.exe",
                            fileExists: "1",
                            fileName: "update3017",
                            html: "Персонифицированный счет<br>&nbsp Обновление справочников<br>&nbsp&nbsp&nbspСправочники ФФОМС \tСправочники ФФОМС (F002, F003, F008, F011, F013, V002,... V021)"
                        }
                     }
                    */
            downloadPath: String
        },
        data: () => ({
            sendDisabled: false,// для refresh по disabled
            sendLoading: false,// для refresh по disabled
            downloadDisabled: false // для refresh по disabled
        }),
        methods:{
            addDownloadPath(fileName){
                return this.downloadPath+fileName
            },
            //---------------------------------------------------------------------------------------------------------
            async sendEmail(i){
              this.sendDisabled=false
              this.sendLoading=true
              this.arr[i].sendingEmail=true
              //console.log("#####",this.name)

              const url = 'http://localhost:9002/email'

              try {
                //let res = await post('http://localhost:3001/simple-cors', {
                //let res = await post('http://localhost:3001/simple-cors', {
                //let res = await post('http://localhost:3001/simple-cors', {
                //let res = await post('http://localhost:3004/simple-cors', {
                //  let res = await post('http://localhost:3004/simple-cors-nocors', {
                //    let res = await post('http://localhost:3004/simple-cors-nooptions', {
                //let res = await post('http://localhost:3002/simple-cors', {
                //let res = await post('http://localhost:3002/email', {
                //let res = await post('http://localhost:3004/mail', {
                let res = await post('https://mailer.liner74.ru/mail', {
                //let res = await post(url, {
                  type: 'updatesliner74',
                  fileNum: this.arr[i].fileNum,
                  fileName: this.arr[i].fileName,
                  body: this.arr[i].letter

                })
                //console.log('res ',res) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
              } catch (err) {
                console.log('###',url,err) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
              }
              //this.loading=false;
              this.arr[i].sendingEmail=false
              this.arr[i].sended=true
              this.sendLoading=false
              this.sendDisabled=true
            },
            //---------------------------------------------------------------------------------------------------------
            async pre_sendEmail(i){
                this.sendDisabled=false
                this.sendLoading=true
                this.arr[i].sendingEmail=true
                //console.log("#####",this.name)

                const url = urlUpdates('mail') //(process.env.NODE_ENV === 'production'?'http://talon74.ru':'http://localhost')+':9002/list'
                //console.log(url)

                try {
                    let res = await post(url, {
                        type: 'updatesliner74',
                        fileNum: this.arr[i].fileNum,
                        fileName: this.arr[i].fileName,
                        body: this.arr[i].letter

                    })
                    //console.log('res ',res) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
                } catch (err) {
                    console.log('###',url,err) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
                }
                //this.loading=false;
                this.arr[i].sendingEmail=false
                this.arr[i].sended=true
                this.sendLoading=false
                this.sendDisabled=true
            },
            isLazy(e){
                let ret=''
                if(e.lazy){
                    ret='v-lazy'
                }
                return ret
            },
            cardColor(fileExists){
                let color=''
                if(fileExists!='1'){
                    color='grey lighten-4'
                }
                return color
            },
            isSended(i){
                return this.arr[i].sended
            },
            isSending(i){
                return this.arr[i].sendingEmail
            },
            sendCaption(i) {
                if(this.arr[i].sended){
                    return 'Сообщение отправлено'
                }else{
                    return 'Отправить сообщение'
                }
            },
            downloadCaption(i) {
                if(this.arr[i].downloaded){
                    return 'Загружено'
                }else{
                    return 'Загрузить'
                }
            },
            setDownloaded(i){
                this.downloadDisabled=false
                //console.log('downloaded........')
                this.arr[i].downloaded=true
                this.downloadDisabled=true
            },
            isDownloaded(i){
                return this.arr[i].downloaded
            }


        }


    }
</script>

<style scoped>

</style>