export default {
    state: {
        tab: null,
        login: false,
        arr: [
        {caption: 'ГЛАВНАЯ', name: 'Home', route: '/'},
        //{caption: 'ГЛАВНАЯ2', name: 'Home2', route: '/home2'},
        //{caption: 'ГЛАВНАЯ3', name: 'Home3', route: '/home3'},
        //{caption: 'ГЛАВНАЯ4', name: 'Home4', route: '/home4'},
        {caption: 'ОБНОВЛЕНИЯ', name: 'Updates', route: '/updates'},
        {caption: 'КОНТАКТЫ', name: 'navContacts', route: '/'},
        {caption: 'КАРТА САЙТА', name: 'navMap', route: '/'}
        ]
    },
    getters:{
        NAV: state=> {
            return state.arr
        },
        NAV_TAB: state=> {
            return state.tab
        },
        NAV_LOGIN: state=> {
            return state.login
        }
    },
    mutations: {
        SET_NAV: (state,arr) =>{
            let add=true
            // найти элемент
            if(Boolean(arr.route)==false) {
                arr.route = "/" + arr.name
            }
            //console.log(state.arr, arr.name)
            for(let i=0; i<state.arr.length; i++){
                if(state.arr[i].name==arr.name){
                    state.tab=i
                    add=false
                }
            }
            if(add){
                //console.log('add')
                state.arr.push(arr)
                state.tab=state.arr.length-1
            }
        },
        DEL_NAV: (state,arr) =>{
            // найти элемент
            for(let i=0; i<state.arr.length; i++){
                if(state.arr[i].name==arr.name){
                    state.arr.splice(i,1)
                    break
                }
            }
        },
        NAV_LOGIN: (state,login) =>{
            state.login=login
        },
        NAV_TAB: (state,tab) =>{
            state.tab=tab
        }
    },
    actions: {
        SET_NAV: (context, arr) => {
            context.commit('SET_NAV',arr)
        },
        DEL_NAV: (context, arr) => {
            context.commit('DEL_NAV',arr)
        },
        NAV_LOGIN: (context, login) => {
            context.commit('NAV_LOGIN', login)
        },
        NAV_TAB: (state,tab) =>{
            context.commit('NAV_TAB', tab)
        }

    }
}

