<template>

    <section>
        <div class="pa-md-16">

            <SectionHeader :header="header"/>


            <div  class="md-16">
                <v-row
                        justify="center"
                        no-gutters
                >
                    <v-col
                            cols="4"
                            sm="2"
                            max-width="400px"
                            v-for="(item,i) in items"
                            :key="i"
                    >

                        <!--color="blue-grey lighten-5"-->
                        <v-list dense
                                flat
                                rounded

                        >
                            <v-subheader class="blue-grey--text font-weight-bold text-md-body-1" v-html="item.header"/>
                            <v-list-item-group

                            >
                                <v-list-item
                                        v-for="(e, i) in item.items"
                                        :key="e.key"
                                        @click="mapClick(e.key)"
                                        :href="e.href"
                                        :disabled="Boolean(e.disabled)"
                                >
                                    <!--v-list-item-icon>
                                        <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon-->
                                    <v-list-item-content
                                            class="black--text font-weight-light">
                                        <v-list-item-title class="text-md-body-1" v-html="e.text"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>


                </v-row>
            </div>
        </div>
    </section>

</template>

<script>
    import SectionHeader from "./SectionHeader";


    export default {
        name: "SectionMapDelete",
        data: () => ({
            items: [
                {
                    header: 'МИС &laquo;Лайнер&raquo;',
                    items: [
                        { text: 'Обновления',           key: 'mapUpdates' },
                        { text: 'Архив обновлений',     key: 'mapUpdatesArc' },
                        { text: 'Преимущества',         key: 'mapAdvantage' },
                        { text: 'Особенности',          key: 'mapFeatures' },
                        { text: 'Решения',              key: 'mapSolution' },
                        { text: 'Оборудование',         key: 'mapServer' },
                        { text: 'Инструкции',           key: 'mapInstructions' },
                        { text: 'Загрузки',             key: 'mapDownload' },
                        { text: 'Старая версия сайта',  key: 'mapOldLiner', href: "http://old.liner74.ru"},
                    ]
                },
                {
                    header: 'Контакты',
                    items: [
                        { text: 'Контакты',         key: 'mapContacts' },
                        { text: 'Обрантная связь',  key: 'mapSend' },
                    ]
                },
                {
                    header: 'Проекты',
                    items: [
                        { text: 'Запись к врачу',   key: 'mapTalon74'   , disabled: '-'},
                        { text: 'Инфокиоск',        key: 'mapInfokiosk' , disabled: '-'},
                        { text: 'Штрих-кода',       key: 'mapBc'        , disabled: '-'},
                    ]
                },
                {
                    header: 'Разное',
                    items: [
                        { text: 'Вход',             key: 'mapLogin' },
                    ]
                },
            ]
        }),
        components: {SectionHeader},
        props: {
            header:  String
        },
        methods: {
            async mapClick(key){
                switch(key){
                    case 'mapUpdates':
                        await this.$router.push('/updates')
                        break;
                    case 'mapUpdatesArc':
                        await this.$router.push('/updatesarc')
                        break;
                    case 'mapAdvantage':
                        try {
                            await this.$router.push('/')
                        }catch(e){console.log(e)}
                        this.$vuetify.goTo('#advantage', {duration:0,offset:0})
                        break;
                    case 'mapFeatures':
                        try {
                            await this.$router.push('/')
                        }catch(e){console.log(e)}
                        this.$vuetify.goTo('#features', {duration:0,offset:0})
                        break;
                    case 'mapOldLiner':
                        break;
                    case 'mapContacts':
                        try {
                            await this.$router.push('/')
                        }catch(e){console.log(e)}
                        this.$vuetify.goTo('#contacts', {duration:0,offset:0})
                        break;
                    case 'mapSend':
                        try {
                            await this.$router.push('/send')
                        }catch(e){console.log(e)}
                        break;
                    case 'mapLogin':
                        this.$store.commit('NAV_LOGIN', false)
                        try {
                            await this.$router.push('/login')
                        }catch(e){console.log(e)}
                        break;
                    case 'mapSolution':
                        //try {
                        //    await this.$router.push('/')
                        //}catch(e){console.log(e)}
                        this.$vuetify.goTo('#solution', {duration:0,offset:0})
                        break;
                    case 'mapServer':
                        this.$vuetify.goTo('#server', {duration:0,offset:0})
                        break;
                    case 'mapInstructions':
                        try {
                            await this.$router.push('/instructions')
                        }catch(e){console.log(e)}
                        break;
                    case 'mapDownload':
                        try {
                            await this.$router.push('/download')
                        }catch(e){console.log(e)}
                        break;

                }
            }
        }
    }
</script>

<style scoped>

</style>