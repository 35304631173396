<template>
    <div  class="pt-8">
        <h1 class="mb-md-6 text-sm-h2 font-weight-bold"
            align="center"
            v-html="header"
        />

        <div class="v-responsive mx-auto mb-8" style="width: 56px;">
            <div class="v-responsive__content">
                <hr role="separator" aria-orientation="horizontal" class="mb-1 v-divider theme--light">
                <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light">
            </div>
        </div>


    </div>

</template>

<script>
    export default {
        name: "SectionHeader",
        props: {
            header:  String
        }

    }
</script>

<style scoped>

</style>