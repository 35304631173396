<template>
    <v-app id="inspire">
        <v-system-bar app>
            <v-spacer></v-spacer>

            <v-icon>mdi-square</v-icon>

            <v-icon>mdi-circle</v-icon>

            <v-icon>mdi-triangle</v-icon>
        </v-system-bar>

        <v-navigation-drawer
                v-model="drawer"
                app
        >
            <v-sheet
                    color="grey lighten-4"
                    class="pa-4"
            >
                <v-avatar
                        class="mb-4"
                        color="grey darken-1"
                        size="64"
                ></v-avatar>

                <div>john@vuetifyjs.com</div>
            </v-sheet>

            <v-divider></v-divider>

            <v-list>
                <v-list-item
                        v-for="[icon, text] in links"
                        :key="icon"
                        link
                >
                    <v-list-item-icon>
                        <v-icon>{{ icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-container
                    class="py-8 px-6"
                    fluid
            >
                <v-row>
                    <v-col
                            v-for="card in cards"
                            :key="card"
                            cols="12"
                    >
                        <v-card>
                            <v-subheader>{{ card }}</v-subheader>

                            <v-list two-line>
                                <template v-for="n in 6">
                                    <v-list-item

                                            :key="n"
                                    >
                                        <v-list-item-avatar color="grey darken-1">
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>Message {{ n }}</v-list-item-title>

                                            <v-list-item-subtitle>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil repellendus distinctio similique
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider
                                            v-if="n !== 6"
                                            :key="`divider-${n}`"
                                            inset
                                    ></v-divider>
                                </template>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        data: () => ({
            cards: ['Today', 'Yesterday'],
            drawer: null,
            links: [
                ['mdi-inbox-arrow-down', 'Inbox'],
                ['mdi-send', 'Send'],
                ['mdi-delete', 'Trash'],
                ['mdi-alert-octagon', 'Spam'],
            ],
        }),
    }
</script>