<template>

    <v-container fluid class="ma-md-auto">
        <!--div>
            <v-alert
                    v-model="sended"
                    border="left"
                    close-text="Close Alert"
                    color="deep-purple accent-4"
                    dark
                    dismissible
            >
                Ваше сообщение отправлено!
            </v-alert>
            <div class="text-center">
                <v-btn
                        v-if="!sended"
                        color="deep-purple accent-4"
                        dark
                        @click="sended = !sended"
                >
                    Закрыть
                </v-btn>
            </div>
        </div-->


        <SectionHeader :header="caption"/>




        <v-card
                class="mx-auto pa-md-5"
                rounded="xl"
                style="max-width: 800px;"
                elevation="5"

        >
            <v-form
                    ref="form"
                    v-model="form"
                    class="pa-6 pt-6"
            >
                <v-text-field
                        v-model="arr.p_name"
                        filled
                        label="Ваше имя, название организации"
                        :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                        v-model="arr.p_email"
                        filled
                        label="Электронная почта"
                        :rules="[rules.email]"
                ></v-text-field>
                <v-text-field
                        v-model="arr.p_subject"
                        filled
                        label="Заголовок"
                        :rules="[rules.required]"
                ></v-text-field>
                <v-textarea
                        v-model="arr.p_body"
                        auto-grow
                        filled
                        label="Сообщение"
                        rows="4"
                        :rules="[rules.required]"
                        shaped
                ></v-textarea>

                <!--v-checkbox
                        v-model="p_agreement"
                        :rules="[rules.required]"
                        label="Принимаю условия использования сервиса"
                >
                </v-checkbox-->


                <v-checkbox
                        v-model="arr.p_agreement"
                        :rules="[rules.required]"
                >
                    <template v-slot:label>
                        Принимаю .&nbsp;
                        <a
                                href="#"
                                @click.stop.prevent="$router.push('/sendlaw')"
                        >условия использования сервиса</a>
                        &nbsp;*
                    </template>
                </v-checkbox>

            </v-form>


            <v-divider></v-divider>
            <v-card-actions class="pa-md-7">
                <v-btn
                        text
                        @click="$refs.form.reset()"
                        rounded
                >
                    Очистить
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn  class="hidden-sm-and-down"
                        text
                        @click.stop.prevent="$router.push('/sendlaw')"
                        rounded
                >
                    Условия использования сервиса
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                        :disabled="!form && !sended"
                        :loading="isSending"
                        class="white--text"
                        color="primary"
                        depressed
                        rounded
                        @click="doSend()"
                >
                    Отправить
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-bottom-sheet v-model="sended">
            <v-sheet
                    class="text-center"
                    height="200px"
            >
                <v-btn
                        class="mt-6"
                        text
                        color="primary"
                        rounded
                        @click="sended = !sended"
                >
                    Закрыть
                </v-btn>
                <div class="py-3" color="primary">
                    <h2>Ваше сообщение отправлено!</h2>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
    import { mdiCheckboxMarked, mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline} from '@mdi/js';
    import SectionHeader from "@/components/app/SectionHeader";
    import {addNavbarTab} from "@/components/app/js/globals";
    import {siteUrl, urlUpdates} from "../components/app/js/globals";
    import post from "../components/app/js/post";

    export default {
        name: "Send",
        data: () => ({
            sended: false,
            caption: "Напишите нам",
            isSending: false,
            form: false,
            arr: {
                p_agreement: false,
                p_name: '',
                p_email: '',
                p_subject: '',
                p_body: ''
            },
            emailRules: [
                v => !!v || 'Обязательно для заполнения!',
                v => /.+@.+/.test(v) || 'Некорректный E-mail'
            ],
            rules: {
                email: v => !!(v || '').match(/@/) || 'Введите правильный email',
                required: v => !!v || 'Поле обязательно для заполнения'
            }
        }),
        props: {
            agreement:  {
                type: String,
                default: null
            },
            name:       {
                type: String,
                default: null
            },
            email:      {
                type: String,
                default: null
            },
            subject:    {
                type: String,
                default: null
            },
            body:       {
                type: String,
                default: null
            },
            header:       {
                type: String,
                default: null
            },
            template:       {
                type: String,
                default: null
            }
        },
        beforeMount() {
            let rest = true

            if(Boolean(this.template)){
                rest=false
                if(this.template.trim().toLowerCase()=='cancelemail'){
                   this.arr.p_name = 'Отписаться'
                   this.arr.p_agreement=true;
                   this.arr.p_subject='###'+this.template+'### Отказ от получения уведомлений с сайта Liner74.ru'
                   this.arr.p_body='Прошу отключить получение уведомлений'
                   this.arr.p_email=this.email
                }
            }else {
                // поменять заголовок?
                if (Boolean(this.header)) {
                    this.caption = this.header
                }


                let rest = true
                //console.log(">>>", this.agreement, "boolean", Boolean(this.agreement), "typeof", typeof this.agreement)
                if (Boolean(this.agreement)) {
                    this.arr.p_agreement = this.agreement
                    rest = false
                }
                if (Boolean(this.name)) {
                    this.arr.p_name = this.name
                    rest = false
                }
                if (Boolean(this.email)) {
                    this.arr.p_email = this.email
                    rest = false
                }
                if (Boolean(this.subject)) {
                    this.arr.p_subject = this.subject
                    rest = false
                }
                if (Boolean(this.agreement)) {
                    this.arr.p_agreement = this.agreement
                    rest = false
                }
                if (Boolean(this.body)) {
                    p_body = this.body
                    rest = false
                }
            }

            if(rest){
                this.doRest()
            }
            addNavbarTab(this,{name: this.$options.name, caption: this.caption})
        },
        beforeDestroy() {
            this.doSave()
        },
        components: {
            SectionHeader
        },
        methods: {
            doRest() {
                this.arr = this.$store.getters.SEND;
            },
            doSave() {
                //this.$store.dispatch('SEND', this.arr)
                this.$store.commit('SEND', this.arr)
            },
            async doSend() {
                this.isSending = true

                //console.log("#####", this.name)

                const url = urlUpdates('mail') //(process.env.NODE_ENV === 'production'?'http://talon74.ru':'http://localhost')+':9002/list'

                try {
                    let res = await post(url, {
                        type: 'send',
                        email: this.arr.p_email,
                        subject: 'Запрос: ' + this.arr.p_name + ': ' + this.arr.p_subject,
                        body: this.arr.p_body
                    })
                    //console.log('res ', res) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
                } catch (err) {
                    console.log('###', url, err) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
                }

                this.$refs.form.reset()
                this.isSending = false
                this.sended=true;
            }
        }

    }
</script>

<style scoped>

</style>