<template>

    <!--src0="/img/home/home37_99px_ru.png"-->
    <HomeComponent
            src0="/img/home/home_liner74_1920.jpg"
            src1="/img/home/home_liner74_2.jpg"
            src2="/img/home/home_liner74_3.jpg"
    ></HomeComponent>

</template>

<script>
    //home37_99px_ru.png
    import HomeComponent from "../components/HomeComponent";
    import {addNavbarTab} from "@/components/app/js/globals";
/*
    src0="/img/home/home37_99px_ru.png"
    src1="/img/home/home3.jpg"
    src2="/img/home/home20_99px_ru.jpg"
    src3="/img/home/home34_99px_ru.jpg"
*/
    export default {
        name: "Home",
        beforeMount() {
            addNavbarTab(this,{caption: 'Главная', name: this.name, route: '/'})
            document.onreadystatechange = () => {
                if (document.readyState == "complete"){
                    if( Boolean(this.goto)) {
                        switch(this.goto){
                            case 'advantage':
                            case 'gipp_infokiosk':
                                this.$vuetify.goTo('#advantage', {duration:350,offset:0})
                                break
                            case 'map':
                                this.$vuetify.goTo('#map', {duration:350,offset:0})
                                break
                            //default:
                            //    this.$router.push('/updates')
                        }
                    }
                }
            }
        },
        props: {
            goto: {  // штрих-код на инфокиоске
                type: String,
                default: null
            }
        },
        components: {
            HomeComponent
        }

    }
</script>

<style scoped>

</style>