export default {
    state: {
        arr: {
            p_agreement: false,
            p_name: '',
            p_email: '',
            p_subject: '',
            p_body: '',
            p_caption: ''
        }
    },
    getters:{
        SEND: state=> {
            return state.arr
        }
    },
    mutations: {
        SEND: (state,payload) =>{
            state.arr=payload
        },
    },
    actions: {
        SEND: (context, payload) => {
            context.commit('SEND',payload)
        },
    },

}