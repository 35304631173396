<template>
    <section id="instruction">

        <div class="pa-md-16">

            <SectionHeader :header="header"/>
            <div  class="md-16">
<!--
            <v-carousel height="auto">
                <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                ></v-carousel-item>
            </v-carousel>
            -->

                <v-row>

                    <v-col
                            cols="12"
                            sm="4"
                            v-for="(e,i) in items"
                            :key="i"
                    >


                        <v-card
                                class="mx-auto"
                                elevation="10"
                                height="auto"
                        >
                            <v-img
                                    :src="e.src"
                            ></v-img>

                            <v-card-title>
                                Шаг {{i+1}}
                            </v-card-title>
                        </v-card>
                    </v-col>

                </v-row>
            </div>
        </div>

    </section>

</template>

<script>

    import {siteUrl} from '../components/app/js/globals';
    import SectionHeader from "../components/app/SectionHeader";



    export default {
        name: "Instruction",
        data: () => ({
            items: null /*[
                {
                     src: siteUrl(8080)+'/static/images/tousers/'+'prof1.png',
                },
                {
                    src: siteUrl(8080)+'/static/images/tousers/'+'prof2.png',
                },
                {
                    src: siteUrl(8080)+'/static/images/tousers/'+'prof3.png',
                },
                {
                    src: siteUrl(8080)+'/static/images/tousers/'+'prof4.png',
                }
            ]*/
        }),
        props: {
            header: {
                type: String,
                default: 'Инструкция'
            },
            name: {
                type: String,
                default: null
            },
            max: {
                type: String,
                default: null
            }
        },
        components: {
          SectionHeader
        },
        beforeMount() {
            //console.log('before mount-----------------', this.max,parseInt(this.max,10))
            let items=[]
            for(let i=0; i<parseInt(this.max,10); i++){
                //items.push({src: siteUrl(8080)+'/static/images/tousers/'+this.name+(i+1)+'.png'})
                items.push({src: '/static/images/tousers/'+this.name+(i+1)+'.png'})
            }
            this.items=items
            //console.log(items)
            //console.log(this.items)
        }

    }
</script>

<style scoped>

</style>