<template>

    <section id="contacts">
        <div class="pa-md-16">
            <div>
                <div class="google-map" ref="googleMap"></div>
            </div>


            <SectionHeader :header="header"/>


            <div  class="md-16">
                <v-row>


                    <v-col
                            cols="12"
                            sm="3"
                    >
                        <v-card
                                class="ma-md-5"
                                max-width="700"
                        >
                            <v-card-title>
                                ООО &laquo;Лайнер&raquo;
                            </v-card-title>

                            <v-card-subtitle  class="text-md-body-1">
                                Информационные системы <q>Лайнер</q>
                            </v-card-subtitle>

                            <v-divider/>
                            <v-card-text  class="text-md-body-1">
                                пл.Революции, д.7, оф. 1.09<br>
                                г.Челябинск, Челябинская обл.,<br>
                                454091

                            </v-card-text>

                            <v-card-actions
                            >
                                <v-btn
                                        color="orange lighten-2"
                                        text
                                        to="/send"
                                >
                                    Напишите нам
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col
                            cols="12"
                            sm="3"
                    >
                        <v-card
                                class="ma-md-5"
                                max-width="700"
                        >
                            <v-card-title>
                                {{tel1}}
                            </v-card-title>

                            <v-card-subtitle  class="text-md-body-1">
                                Телефон для связи
                            </v-card-subtitle>

                            <v-divider/>
                            <v-card-text  class="text-md-body-1">
                                Городской номер<br><br><br>
                            </v-card-text>

                            <v-card-actions
                            >
                                <v-btn
                                        color="orange lighten-2"
                                        text
                                        :href="TEL1TO"
                                >
                                    Позвонить
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col
                            cols="12"
                            sm="3"
                    >
                        <v-card
                                class="ma-md-5"
                                max-width="700"
                        >
                            <v-card-title>
                                {{email}}
                            </v-card-title>

                            <v-card-subtitle class="text-md-body-1">
                                Электронная почта
                            </v-card-subtitle>

                            <v-divider/>
                            <v-card-text  class="text-md-body-1">
                                Email<br><br><br>
                            </v-card-text>

                            <v-card-actions
                            >
                                <v-btn
                                        color="orange lighten-2"
                                        text
                                        :href="EMAILTO"
                                >
                                    Написать
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>


                    <v-col
                            cols="12"
                            sm="3"
                    >
                        <v-card
                                class="ma-md-5"
                                max-width="700"
                        >
                            <v-card-title>
                                {{tel2}}
                            </v-card-title>

                            <v-card-subtitle  class="text-md-body-1">
                                Телефон для связи
                            </v-card-subtitle>

                            <v-divider/>
                            <v-card-text  class="text-md-body-1">
                                Федеральный номер<br><br><br>
                            </v-card-text>

                            <v-card-actions
                            >
                                <v-btn
                                        color="orange lighten-2"
                                        text
                                        :href="TEL2TO"
                                >
                                    Позвонить
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>




                </v-row>
            </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2279.527755360911!2d61.40198091632395!3d55.156542245285976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43c592ad546415d9%3A0xdc8cb5e790209e1e!2z0J7QntCeICLQm9Cw0LnQvdC10YAi!5e0!3m2!1sru!2sru!4v1611213462828!5m2!1sru!2sru"
                width="100%"
                height="400px"
                frameborder="0"
                style="border:0;"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
                alt="Карта GoogleMaps" >

        </iframe>
    </section>

</template>

<script>
    import {tel2telto,email2mailto} from "@/components/app/js/tools.js";
    import {phoneCell, phoneCity, emailLiner} from "@/components/app/js/globals.js";
    import SectionHeader from "./SectionHeader";


    export default {
        name: "SectionContacts",
        data: () => ({
            tel1: phoneCity(), // "+7 (351) 231-23-60",
            tel2: phoneCell(), //"+7 (908) 081-23-60",
            email: emailLiner() //"312360@mail.ru"
        }),
        components: {SectionHeader},
        props: {
            header:  String
        },
        computed: {
            TEL1TO() {
                return tel2telto(this.tel1)
            },
            TEL2TO() {
                return tel2telto(this.tel2)
            },
            EMAILTO() {
                return email2mailto(this.email,'Обращение с сайта  liner74.ru.','Задайте интересующий вас вопрос и мы с удовольствием вам ответим.')
            }
        }
    }
</script>

<style scoped>

</style>