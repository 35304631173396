<template>

    <v-footer
            color="blue-grey lighten-5"
            padless
            id="footer"
    >




    <v-row
            justify="center"
            no-gutters
    >

        <v-col
                class="py-4 text-center"
                cols="12"

        >

            <!--a href="https://liner74.ru">&copy; {{ new Date().getFullYear() }} Copyright ООО <q>Лайнер</q></a-->

            <!--{{ new Date().getFullYear() }} — <strong>Лайнер</strong>-->
            &copy;2010-{{ new Date().getFullYear() }} .ver 02.08. liner4 Разработано <strong>ООО &laquo;Лайнер&raquo;</strong>
        </v-col>
    </v-row>

    </v-footer>

    <!--v-footer
            padless
            class="text-center blue-green lighten-4"
    >
        <v-card
                flat
                tile
                width="100%"
        >
            <v-card-text
                    class="gray--text"
            >
                Данный сервис разработан для комфортной записи в лечебные учреждения.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
                <a href="https://liner74.ru">&copy; {{curyear}} Copyright ООО <q>Лайнер</q></a>
            </v-card-text>
        </v-card>
    </v-footer-->
</template>

<script>
    import SectionHeader from "./SectionHeader";

    export default {
        name: 'SiteFooter',
        computed: {
            curyear() {
                return new Date().getFullYear()
            }
        },
        data: () => ({
            links: [
                'Home',
                'About Us',
                'Team',
                'Services',
                'Blog',
                'Contact Us',
            ],
            items: [
                {
                    header: 'Контакты',
                    items: [
                        { text: 'Real-Time', key: 1 },
                        { text: 'Audience', key: 2 },
                        { text: 'Conversions', key: 3 },
                        { text: 'Conversions', key: 31 },
                        { text: 'Conversions', key: 32},
                        { text: 'Conversions', key: 33 },
                    ]
                },
                {
                    header: 'Проекты',
                    items: [
                        { text: 'Запись к врачу', key: 4 },
                        { text: 'Инфокиоск', key: 5 },
                        { text: 'Conversions', key: 6 }
                    ]
                },
                {
                    header: 'Проекты2',
                    items: [
                        { text: 'Запись к врачу', key: 7 },
                        { text: 'Инфокиоск', key: 8 },
                        { text: 'Conversions', key: 9 }
                    ]
                }
            ]
        }),
        components: {SectionHeader}

    }

</script>