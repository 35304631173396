<template>
        <section id="updates" >
            <UpdatesComponent
                    header="Обновления"
                    downloadPath="downloads/updates/"
                    v-bind:name="$options.name"
            />
            <div class="text-center mt-15 mb-15">
                <v-btn
                        rounded
                        color="grey darken-2"
                        dark
                        @click="clickUpdatesArc()"
                >
                    Архив обновлений
                </v-btn>
            </div>

        </section>

</template>

<script>
    import UpdatesComponent from "@/components/UpdatesComponent";
    import goTo from 'vuetify/lib/services/goto';



    export default {
        name: "Updates",
        components: {
            UpdatesComponent
        },
        props: {
            goto: {
                type: String,
                default: null
            }
        },
        mounted() {
            document.onreadystatechange = () => {
                if (document.readyState == "complete" && Boolean(this.goto)) {
                   this.gotoId(this.goto)
                   // this.$vuetify.goTo('#'+this.goto, {duration:350,offset:0})

                }
            }
            /* -- будет срабатывать два раза - незачем (но в safari оба метода не работают)
            window.addEventListener('load', () => {
                this.gotoId(this.goto)
                // run after everything is in-place
            })
            */
        },
        updated: function () {
            this.$nextTick(function () {
                this.gotoId(this.goto)
            })
        },
        methods: {
            gotoId(id){
                goTo('#'+id, {duration: 350, offset: 0})
                //console.log('gotoId>>>>>>')
            },
            async clickUpdatesArc(){
                try{
                    await this.$router.push('/updatesarc')
                }catch(e){console.log(e)}
            }
        }



    }
</script>

<style>
    /*
    #app {
        background: url('https://ohlaladani.com.br/wp-content/uploads/wallpaper-OHLALADANI_DESKTOP_WALLPAPERS_AVENTURA-2.jpg')
        no-repeat center center fixed !important;
        background-size: cover;
    }
    */
</style>
