
<template>

    <div>

        <HomeLiner :src0="src0"/>
        <!--v-lazy не ставить - не будет работать переход scroll по кнопке из HomeLiner-->
        <SectionCardShow
                id="advantage"
                header="ПРЕИМУЩЕСТВА"
                v-bind:arr="arradv"
        />

        <!--v-lazy-->
        <SectionCardShow
                id="features"
                class="blue-grey lighten-5"
                header="ОСОБЕННОСТИ"
                v-bind:arr="arrfea"
        />
        <!--/v-lazy-->

        <!--src="/img/home/home3.jpg"-->
        <!--src="/img/home/thefifthelement-liner.png"-->
        <!--v-lazy
            v-if="Boolean(src1)"
        -->
            <v-parallax
                    v-if="Boolean(src1)"
                    :src="src1"

            />
        <!--/v-lazy-->

        <!--v-lazy-->
        <SectionSolution
                id="solution"
                class="blue-grey lighten-5"
                header="РЕШЕНИЯ"
        />
        <SectionCardShow2
                id="server"
                class="blue-grey lighten-4"
                header="ОБОРУДОВАНИЕ"
                v-bind:arr="arrsrv"
        />
        <!--/lazy-->


        <!--src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"-->
        <!--src="/img/home/home2.jpg"-->
        <!--v-lazy
                v-if="Boolean(src2)"
        -->
            <v-parallax
                    v-if="Boolean(src2)"
                    :src="src2"
            />
        <!--/v-lazy-->


        <!--v-lazy-->
        <SectionContacts
                id="contacts"
                header="КОНТАКТЫ"
        />
        <!--/v-lazy-->


        <!--v-lazy
                v-if="Boolean(src3)"
        -->
            <v-parallax
                    v-if="Boolean(src3)"
                    :src="src3"
            />
        <!--/v-lazy-->
        <!--SectionMapDelete
                id="map"
                header="КАРТА САЙТА"
        /-->

        <SectionMap
                id="map"
                header="КАРТА САЙТА"
        />

    </div>
</template>

<style scope>
    .v-card--reveal {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
    }
</style>

<script>

    // https://store.vuetifyjs.com/products/parallax-theme-free/preview
    import HomeLiner from "./app/HomeLiner";
    import SectionCardShow from "./app/SectionCardShow";
    import SectionCardShow2 from "./app/SectionCardShow2";
    import SectionContacts from "./app/SectionContacts";
    import SectionMapDelete from "./app/SectionMapDelete";
    import SectionMap from "./app/SectionMap";
    import SectionSolution from "./app/SectionSolution";

    //import GoogleMapsApiLoader from 'google-maps-api-loader'



    export default {
        name: 'HomeComponent',
        props: {
            src0: String,
            src1: String,
            src2: String,
            src3: String
        },
        data: () => ({
            arradv: [
                {
                    src: '/img/home/pers700x467.jpg',
                    header: 'Удобно',
                    text: 'Быстрый интерфейс',
                    fulltext: '<p>Интерфейс <b>простой и удобный, не требует от персонала особых навыков</b>.'+
                        '<p>Система отказоусточивая и быстрая, независимо от конфигурации вашего компьютера.</p>',
                    reveal: false
                },
//                '<p>Солидарны с идеей, что идеальная клавиатура для работы с Лайнер должна иметь только одну большую клавишу-педаль, и лучше, чтобы она была <q>ножной</q>, тогда и руки свободны:)</p>'+
//                '<p>На практике применение сканеров в разы уменьшает время поиска данных. Но имеются и другие оригинальные варианты  поиска.</p>'+
//                '<p>Информационная система Лайнер <b>не требует от персонала особых навыков, отказоусточивая и быстрая</b>, независимо от конфигурации вашего компьютера.</p>',
                {
                    src: '/img/home/ai700x467.jpg',
                    header: 'Надежно',
                    text: 'Сопровождение по системе <q>Все включено!</q>',
                    fulltext: '<p>Экосистема Лайнер изначально настроена на <b>встроенную систему сопровождения нашими высококвалифицированными ИТ специалистами</b>. На месте нет необходимости содержать целый ИТ отдел - достаточно  наличие одного <q>продвинутого</q> пользователя, который умеет работать с компьютерной мышкой</p>'+
                        '<p>Версия программного обеспечения – самая свежая в отличие от коробочных МИСов, где обновления нужно постоянно докупать.</p>',
                    reveal: false
                },
//                '<p>Пользователи Лайнер могут нажимать любые кнопки в любом порядке и они не <q>сломают</q> программу. Оригинальная система прав и мониторинга  позволяет руководителю быть спрокойным за целостность данных в Лайнер.</p>',
//        fulltext: '&nbsp&nbsp&nbsp&nbsp Не секрет - <q>коробочных</q> решений нет. Любая внедренная информационная система нуждается в поддержке ее работоспособности и сохранении уникальных данных, которые и составляют один из главных активов предприятия.  Не каждый может себе позволить иметь в штате дюжину ИТ специалистов, грамотных в разных областях. Экосистема Лайнер изначально настроена так, что наличие ИТ персонала в штате не обязательно - достаточно одного <q>продвинутого</q> оператора, который умеет нажимать кнопку мышки. Общение пользователей с Лайнер организовано через оригинальную систему прав доступа, автоматически протоколируемую и легко контролируемую руководителем. Операторы могут нажимать любые кнопки, согласно выданным правам и они не смогут навредить системе. <q>Все включено</q> - это отлаженная система сопровождения Лайнер.',
                {
                    src: '/img/home/eq700x467.jpg',
                    header: 'Комфортно',
                    text: 'Электронная очередь',
                    fulltext:   '<p>Комфорт пользователей и клиентов Лайнер обеспечивают <b>дополнительные модули</b> <q>Лайнер-Электронная очередь</q>, <q>Лайнер-Самозапись через терминал</q>,  <q>Лайнер-Интернет-портал записи к врачу</q>,  <q>Лайнер-СМС оповещение</q>, <q>Лайнер-электронная почта</q> и пр.</p>' +
                        '    <p>Все модули интегрированы с Лайнер и  работают в синхронном режиме. Работа в единой  системе Лайнер удобнее работы с <q>венегретом</q> программ.</p>' +
                        '    <p>Например только в оригнальном модуле <q>Лайнер-Электронная очередь</q> могли быть реализованы следующие возможности:</p>' +
                        '    <p><ul>' +
                        '      <li>Лайнер информирует регистратора об услугах которые выбрал клиент, используя их полное наименование а не шифр из букв и цифр.</li>' +
                        '      <li>Сквозная нумерация талонов до 3х цифр не запутывает клиентов в очереди.</li>' +
                        '      <li>Наличие на талоне оригинального штрих-кода позволяет врачу, при необходимости,  быстро отправить клиента  в обслужившее его ранее окно вне очереди.</li>' +
                        '      <li>Система знает о задействованных окнах в регистратуре и об услугах, которые в этих окнах оказываются на текущий момент. Оператор может в режиме реального времени изменять список обслуживаемых им услуг.</li>' +
                        '      <li>Есть и другие <q>ноу-хау</q>, в которые посвящены только уважаемые пользователи Лайнер.</li>' +
                        '    </ul></p>',
                    reveal: false
                }

            ],
            arrfea: [
                {
                    src: '/img/home/star377x250.jpg',
                    header: 'Дешево',
                    text: 'Использование термопринтеров',
                    fulltext: '<p>Расходные материалы для лазерного принтера - это дорогие оригинальные картриджи и бумага хорошего качества. Если использовать альернативные картриджи, бумагу, тонер - то принтер сам быстро  превращается в дорогостоящий расходный метериал.</p>'+
                        '<p>В Лайнере предусмотрена  работа на принтерах, работающих <b>с дешевыми рулонами термобумаги - без картриджей</b>. Бонусом - космическая скорость печати 1.5 сек (в 10 раз быстрее лазерного принтера) и автоматическая отрезка встроенной системой ножей.</p>'+
                        '<p>Термопринтер - это термоголовка в красивом корпусе, срок службы которой составляет примерно <strong>26 лет</strong>. Одного дешевого рулона термобумаги хватает для печати <strong>800</strong> <q>документов</q>.</p>'+
                        '<p>Время взять калькулятор и посчитать выгоду.</p>',
                    reveal: false
                },
                {
                    src: '/img/home/bc375x250.jpg',
                    header: 'Штрих-кода',
                    text: 'Быстрый поиск',
                    fulltext: '<p>В Лайнер встроена оригинальная система штрих-кодов, которая позволяет пользователю мгновенно обратиться к документу из громадного массива данных.</p>'+
                        '<p>Уникальность системы состоит в том, что <b>чем больше данных, тем быстрее</b> Лайнер ведет поиск. Используются компактные по размеру одномерные штрих-кода, считывающиеся любым дешевым сканером.</p>'+
                        '<p>Предусмотрена возможность использования дорогих 2d сканеров  - например для работы с полисами ОМС нового образца.',
                    reveal: false
                },
                {
                    src: '/img/home/cash375x250.jpg',
                    header: 'Интеграция',
                    text: 'Кассовые аппараты и пр.',
                    fulltext: '<p>Лайнер работает с фискальным регистратором. Любые варианты оплат: <b><i>наличные</i></b>, <b><i>банковской карточкой</i></b>, <b><i>безналичный платеж</i></b>, <b><i>возврат</i></b> и пр.</p>'+
                        '<p>Руководитель или главный бухгалтер получает отчет по кассе в любом нужном разрезе, за любой период. Учитывается актуальный прайс предоставляемых услуг, скидки, возвраты и пр.</p>',
                    reveal: false
                }

            ],
            arrsrv: [
                {
                    src: '/img/home/server805x418.png',
                    header: 'Сервер',
                    text: 'Согласованная конфигурация',
                    fulltext: '<p>Все данные надежно хранятся на сервере Лайнер, что обеспечивает отличную защиту и максимальное быстродействие программы на рабочих местах.</p>',
                    action: {
                        show: true,
                        caption: 'Пример счета',
                        file: 'server_temp.pdf'
                    }
                },
                {
                    src: '/img/home/computer805x418.png',
                    header: 'Рабочая станция',
                    text: 'Любая конфигурация для запуска win32 приложения',
                    fulltext: '<p>Компьютеры пользователей не требуют настроек, данные невозможно скопировать, доступ ко всей информации только из Лайнер.<br><br><br><br></p>',
                    action: {
                        show: false
                    }
                },
                {
                    src: '/img/home/ups805x418.png',
                    header: 'Источник бесперебойного питания',
                    text: 'Согласованный ИБП для сервера',
                    fulltext: '<p>Правильно выбранная конфигурация серверного источника бесперебойного питания обеспечит бесперебойную и комфортную работу всех пользователей Лайнер.<br><br><br></p>',
                    action: {
                        show: false
                    }
                }

            ],
            target: '#advantage'

        }),
        components: {
            SectionCardShow,
            SectionCardShow2,
            SectionContacts,
            SectionSolution,
            SectionMapDelete,
            SectionMap,
            HomeLiner
        }


    }
</script>
