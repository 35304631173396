<template>
    <!--v-lazy не ставить - не работает переход scroll по кнопки-->
    <!--div class="pl-ms-16 pr-md-16"-->
    <section id="updates">
        <!--v-parallax src="/img/home/home_liner74_ok3.jpg"-->
        <div class="ma-md-16">

            <SectionHeader :header="header"/>

            <v-card
                    class="ma-md-5"
                    :loading="loading"
                    flat

            >
                <v-card-text
                        class="text-center text-md-h6">
                    Уважаемые пользователи Медицинской информационной системы &laquo;Лайнер&raquo;.<br>
                    После загрузки файла обновления, запустите его на вашем компьютере и следуйте инструкциям.
                </v-card-text>

            </v-card>

            <UpdateCard
                    v-bind:arr="arr"
                    v-bind:downloadPath="downloadPath"
            >
            </UpdateCard>

            <!--v-btn
                    @click="onClick()">
                нажми
            </v-btn-->


        </div>
        <!--/v-parallax-->

    </section>

</template>

<style scoped>

</style>

<script>    import post, {postLog} from '@/components/app/js/post'
    import SectionHeader from "@/components/app/SectionHeader";
    import {addNavbarTab} from "@/components/app/js/globals";
    import UpdateCard from "@/components/app/UpdateCard";
    import {urlUpdates} from "@/components/app/js/globals";
    import {siteLiner} from "./app/js/globals";
    import {fs} from "fs";


export default {
        props: {
            header: String,
            downloadPath: String, // "downloads/updates/", "downloads/updates/arc/"
            name: String //Updates, UpdatesArc
        },
        data: () => ({
            //image: {backgroundImage: "url(\"https://ohlaladani.com.br/wp-content/uploads/wallpaper-OHLALADANI_DESKTOP_WALLPAPERS_AVENTURA-2.jpg\")"},
            loading: true,
            arr: [],
            /*{
                        3016: {
                            fileDateTime: "28.12.2020 02:55:38",
                            fileDownload: "/Users/user/Downloads/dev/liner4/src/downloads/update/update3016.exe",
                            fileExists: "0",
                            fileName: "update3016",
                            html: "Персонифицированный счет<br>&nbsp Обновление справочников<br>&nbsp&nbsp&nbspСправочники ФФОМС Справочники ФФОМС"
                        },
                        3017: {
                            fileDateTime: "28.12.2020 02:55:38",
                            fileDownload: "/Users/user/Downloads/dev/liner4/src/downloads/update/update3017.exe",
                            fileExists: "1",
                            fileName: "update3017",
                            html: "Персонифицированный счет<br>&nbsp Обновление справочников<br>&nbsp&nbsp&nbspСправочники ФФОМС \tСправочники ФФОМС (F002, F003, F008, F011, F013, V002,... V021)"
                        }
                     }
                    */
        }),
        async beforeMount() {
            //console.log("#####",this.name)
            addNavbarTab(this,{name: this.name, route: '/'+this.name.toLowerCase()})

            const url = urlUpdates('list') //(process.env.NODE_ENV === 'production'?'http://talon74.ru':'http://localhost')+':9002/list'
            //console.log(url)

            try {
                //console.log('downloadPath',url, this.downloadPath)
                let res = await post(url, { downloadPath: this.downloadPath})
                //console.log('url',url)
                //console.log('res.data',res.data)
                this.arr=res.data
                //console.log('res',res.data)


                for(let i=0; i<this.arr.length; i++){
                   this.arr[i].lazy=(i>10) //первые 10 вывести быстро
                   this.arr[i].sendingEmail=false
                   this.arr[i].sended=false
                   this.arr[i].downloaded=false
/*
                   this.arr[i].letterpre=`
                   <!DOCTYPE html>
                    <html>
                    <title>${this.arr[i].fileName}</title>
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    <!--link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"-->
                    <body>

                    <div class="w3-container">
                        <h2><a href="${siteUrl(8080)+'/updates'}">Обновления МИС &laquo;Лайнер&raquo;</a></h2>
                        <p>Для перехода на страницу сайта Liner74.ru нажмите номер обновления</p>

                        <div class="w3-card-4" style="width:100%;">
                            <header class="w3-container w3-blue">
                                <h1><a href="${siteUrl(8080)+'/updates?goto='+this.arr[i].fileName}">${this.arr[i].fileName}</a></h1>
                            </header>

                            <div class="w3-container">
                                <p>${this.arr[i].html}</p>
                            </div>

                            <footer class="w3-container w3-blue">
                                <h5>Отписаться от получения уведомлений <a href="${siteUrl(8080)+'/send?template=cancelemail&email=.email.'}">по этой ссылке</a></h5>
                            </footer>
                        </div>
                    </div>

                    </body>
                    </html>
                    `
*/
/*

                    this.arr[i].letter=`
                   <!DOCTYPE html>
                    <html>
                    <title>${this.arr[i].fileName}</title>
                    <meta charset="utf-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
                    <body>

                        <h2><a href="${siteLiner(8080)+'/updates'}">Обновления МИС &laquo;Лайнер&raquo;</a></h2>
                        <p>Для перехода на страницу сайта Liner74.ru нажмите номер обновления</p>

                        <div class="w3-card-4" style="width:100%;">
                            <header class="w3-container w3-dark-grey">
                                <h1><a href="${siteLiner(8080)+'/updates?goto='+this.arr[i].fileName}">${this.arr[i].fileName}</a></h1>
                            </header>

                            <div class="w3-container">
                                <p>${this.arr[i].html}</p>
                            </div>

                            <!--footer class="w3-container w3-blue-grey">
                                <h5>Отписаться от получения уведомлений <a href="${siteLiner(8080)+'/send?template=cancelemail&email=.email.'}">по этой ссылке</a></h5>
                            </footer-->
                        <small>Отписаться от получения уведомлений <a href="${siteLiner(8080)+'/send?template=cancelemail&email=.email.'}">по этой ссылке</a></small>
                        </div>
                        <img src="cid:cidJpg" style="width: 100%"/>

                    </body>
                    </html>
                    `


 */
                  this.arr[i].letter=`
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta content="text/html; charset=UTF-8" http-equiv="Content-Type">
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <title>${this.arr[i].fileName}</title>
</head>
<body style="margin: 0; padding: 0;">
 <table border="0" cellpadding="30" cellspacing="0" width="100%">
     <tr>
         <td>
             <table border="0" width="600" cellspacing="0" cellpadding="0" align="center" style="border-collapse: collapse; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);" >
                 <tbody>
                 <tr>
                     <td align="center" style="padding: 0px 0px 0px 0px;"">
                         <a href="${siteLiner(8080)+'/updates?goto='+this.arr[i].fileName}">
                            <img width="600" style="display: block;" alt="Обновления для Лайнер" src="https://liner74.ru/liner-mailer-header.png">
                         </a>
                     </td>
                 </tr>
                 <tr>
                     <td align="center" style="padding: 5px 10px 5px 0px;" bgcolor="#326AA3">
                         <h1><a style="color:#FFFFFF;" href="${siteLiner(8080)+'/updates?goto='+this.arr[i].fileName}">${this.arr[i].fileName}</a></h1>
                     </td>
                 </tr>


                 <tr>
                     <td bgcolor="#ffffff" style="padding: 40px 30px 40px 30px;">
                         <table border="0" cellpadding="0" cellspacing="0" width="100%">
                             <tr>
                                 <td>
                                     <h3>
                                     <meta charset="windows-1251">${this.arr[i].html}
                                     </h3>
                                 </td>
                             </tr>
                         </table>
                     </td>
                 </tr>
                 <tr>
                     <td bgcolor="#f8f8f8" style="padding: 0px 30px 0px 30px;">
                         <table border="0" cellpadding="0" cellspacing="0" width="100%">
                             <tr>
                                 <td style="padding: 20px 0px 1px 0px;">
                                     Инструкция по загрузке обновления:
                                 </td>
                             </tr>
                             <tr>
                                 <td style="padding: 1px 0px 5px 0px;">
                                     Для перехода на страницу сайта Liner74.ru нажмите на номер обновления
                                 </td>
                             </tr>
                             <tr>
                                 <td style="padding: 20px 0px 10px 0px;">
                                     Отписаться от получения уведомлений <a href="${siteLiner(8080)+'/send?template=cancelemail&email=.email.'}">по этой ссылке</a>
                                 </td>
                             </tr>
                         </table>
                     </td>
                 </tr>
                 <!--tr>
                     <td bgcolor="#1e90ff">Привет!</td>
                 </tr-->
                 </tbody>
             </table>

         </td>


     </tr>
 </table>
</body>
</html>
                    `


                    //cancelSubject: '###cancelemail### Отказ от получения уведомлений с сайта Liner74.ru',
                    //cancelBody: 'Прошу отключить получение уведомлений'
                }
                //console.log('<>>>>>>>>>>>',this.arr[0].letter)
                //console.log(`POST RECEIVED from ${url} ----: `, this.arr)


            } catch (err) {
                console.log(err) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
            }
            this.loading=false;
        },
        components: {
            SectionHeader, UpdateCard
        }/*,
        methods:{
            onClick(){
                console.log('fs start')
                const fs = require('fs')
                fs.appendFile('mynewfile1.txt', 'Hello content!', function (err) {
                    if (err) throw err
                    console.log('Saved!')
                })

                //const contentMain = fs.readFileSync('*', { encoding: 'utf-8' })
                //const lines = contentMain.split(/\r?\n/g)
                console.log('fs end',fs)

            }

        }
        */

    }
</script>

