import JSON_to_URLEncoded from "./json2url";
import axios from "axios";
import https from 'https'

export default function post(url, jsonData){

    const urlData=JSON_to_URLEncoded(jsonData)
    const axiosConfig= {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
        },
        httpsAgent: new https.Agent({
            rejectUnauthorized: false
        })
    }

    return new Promise((resolve,reject) => {
        axios.post(url, urlData, axiosConfig)
            .then((res) => {
                resolve(res)

            })
            .catch((err) => {
                reject(err)
            })
    })
}

//---------------------------------------------------------------------------------------------------
async function postLog(url,arr) {
    //console.log('postLog',url,arr)
    return post(url + '/log',arr )
}




export {postLog} // список экспортируемых переменных
