<template>
    <section id="updates">

        <UpdatesComponent
                header="Архив обновлений"
                downloadPath="downloads/updates/arc/"
                v-bind:name="$options.name"

        />
        <div class="text-center mt-15 mb-15">
            <v-btn
                    rounded
                    color="grey darken-2"
                    dark
                    @click="clickUpdates()"
            >
                Актуальные обновления
            </v-btn>
        </div>

    </section>

</template>

<script>
    import UpdatesComponent from "@/components/UpdatesComponent";

    export default {
        name: "UpdatesArc",
        components: {
            UpdatesComponent
        },
        methods: {
            async clickUpdates(){
                try{
                    await this.$router.push('/updates')
                }catch(e){console.log(e)}
            }
        }


    }
</script>

<style scoped>

</style>