<template>

    <!--v-lazy не ставить - не работает переход scroll по кнопки-->
    <section>
        <div class="pa-md-16">

            <SectionHeader :header="header"/>
            <div  class="md-16">
                <p style="text-align:justify text-md-body-1">Информационные системы на базе Лайнер работают в разных областях. В основном это медицина, но существуют приложения для энергосбытовых компаний, телекоммуникационных, страховых учреждений областного и федерального уровня, государственного и частного финансирования. Этот сайт технически используется для оперативной доставки обновлений для той части пользователей, которые работают в единой медицинской информационной системe &laquo;Лайнер&raquo;, покрывающей медицинские предприятия широкого профиля с частным и государственным финансированием.</p>
                <v-row>

                    <v-col
                            cols="12"
                            sm="6"
                    >

                        <v-card
                                class="ma-md-5"
                        >

                            <v-card-title >
                                В МИС &laquo;Лайнер&raquo; встроены наиболее популярные модули:
                            </v-card-title>

                            <v-card-subtitle class="text-justify text-md-body-1">
                                <p/>
                                <ul type="square">
                                    <li>сдача персонифицированного счета,</li>
                                    <li>статистические отчеты в Минздрав и Управление здравоохранения города</li>
                                    <li>собственная (внутренняя) статотчетность</li>
                                    <li>интеграция с ФОМС</li>
                                    <li>документооборот ЛПУ</li>
                                    <li>электронная амбулаторная карта</li>
                                    <li>интеграция с кассовыми аппаратами</li>
                                    <li>интеграция с 1С</li>
                                    <li>интеграция с сайтом записи на прием</li>
                                    <li>интеграция с выделенным сервером смс-сообщений</li>
                                    <li>интеграция с рентгеновским кабинетом</li>
                                    <li>интеграция с медицинскими анализаторами</li>
                                    <li>интеграция с принтерами этикеток штрих-кодов</li>
                                    <li>интеграция со сканерами штрих-кода</li>
                                    <li>интеграция с термопритерами</li>
                                    <li>интеграция с информационными киосками самозаписи (терминалами)</li>
                                    <li>интеграция с операторами call-центра</li><br><br><br><br><br>
                                </ul>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col
                            cols="12"
                            sm="6"
                    >

                    <v-card
                                class="ma-md-5"
                        >

                            <v-card-title>
                                Реализованы следующие рабочие места и  функционал:
                            </v-card-title>

                            <v-card-subtitle class="text-justify text-md-body-1">
                                <p/>
                                <ul type="square">
                                    <li>Регистратура</li>
                                    <li>Прививочный кабинет</li>
                                    <li>Диспансерная группа</li>
                                    <li>Стационар</li>
                                    <li>Параклиника</li>
                                    <li>Листки нетрудоспособности</li>
                                    <li>Инвалиды</li>
                                    <li>Экспертная оценка</li>
                                    <li>Кабинет УЗИ</li>
                                    <li>Касса</li>
                                    <li>Флюоротека</li>
                                    <li>Паспорт здоровья</li>
                                    <li>Паспорт здоровья – дети сироты</li>
                                    <li>Паспорт здоровья – дети спортсмены</li>
                                    <li>Статистика</li>
                                    <li>Ежедневная, Ежемесячная, Годовая отчетность</li>
                                    <li>Работа с территориями</li>
                                    <li>Договорной отдел</li>
                                    <li>Диагностическая лаборатория</li>
                                    <li>Стоматология</li>
                                    <li>Ортопедия</li>
                                    <li>Отоларинголог и др.</li>
                                </ul>
                            </v-card-subtitle>
                        </v-card>
                    </v-col>


                </v-row>
            </div>
        </div>
    </section>

</template>

<script>
    import SectionHeader from "./SectionHeader";
    import {
        mdiChevronUp,
        mdiChevronDown
    } from '@mdi/js';

    export default {
        name: "SectionSolution",
        data: () => ({
            iconChevronUp: mdiChevronUp,
            iconChevronDown: mdiChevronDown
        }),
        components: {SectionHeader},
        props: {
            header:  String
        }
    }
</script>

<style scoped>

</style>