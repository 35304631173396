import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Send from "../views/Send";
import SendLaw from "../views/SendLaw";
import Updates from "../views/Updates";
import Main2 from "../views/Main2";
import UpdatesArc from "../views/UpdatesArc";
import Instruction from "../views/Instruction";
import Instructions from "../views/Instructions";
import Download from "../views/Download";

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: (route) => ({
      goto:           route.query.goto  //http://localhost:8080/?goto=gipp    false(&agreement=) true(&agreement=1)
    })
  },
  {
    path: '/sendlaw',
    name: 'sendlaw',
    component: SendLaw
  },
  {
    path: '/send',
    name: 'send',
    component: Send,
    props: (route) => ({
      agreement:  route.query.agreement, //http://localhost:8080/send?body=true&subject=aldmmd&agreement=    false(&agreement=) true(&agreement=1)
      name:       route.query.name,
      email:      route.query.email,
      subject:    route.query.subject,
      body:       route.query.body,
      header:     route.query.header,
      template:   route.query.template //?template=cancelemail&email=m@m.ru - шаблон  отказа от подписки - agreement... header формируются на строне сайта из-за прроблем с rest кодировкой

    })
  },
  {
    path: '/updates',
    name: 'Updates',
    component: Updates,
    props: (route) => ({
      goto:  route.query.goto //http://localhost:8080/updates?goto=update3022
    })
  },
  {
    path: '/updatesarc',
    name: 'UpdatesArc',
    component: UpdatesArc
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/main2',
    name: 'Main2',
    component: Main2
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/instruction',
    name: 'instruction',
    component: Instruction,
    props: (route) => ({
      header:       route.query.header,
      name:      route.query.name, //http://localhost:8080/send?body=true&subject=aldmmd&agreement=    false(&agreement=) true(&agreement=1)
      max:       route.query.max
    })
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: Instructions
  },
  {
    path: '/*',
    name: 'Default',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
