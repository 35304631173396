<template>
    <!--v-lazy не ставить - не работает переход scroll по кнопки-->
    <!--div class="pl-ms-16 pr-md-16"-->
    <section id="downloadcomponent">
        <!--v-parallax src="/img/home/home_liner74_ok3.jpg"-->
        <div class="ma-md-16">

            <SectionHeader :header="header"/>

            <v-card
                    class="ma-md-5"
                    :loading="loading"
                    flat

            >
                <v-card-text
                        class="text-center text-md-h6">
                    Уважаемые пользователи сайта.<br>
                    На этой странице выложены файлы, необходимые для технического сопровождения.
                </v-card-text>

            </v-card>

            <UpdateCard
                    v-bind:arr="arr"
                    v-bind:downloadPath="downloadPath"
            >
            </UpdateCard>


        </div>
        <!--/v-parallax-->

    </section>

</template>

<style scoped>

</style>

<script>
    import post, {postLog} from '@/components/app/js/post'
    import SectionHeader from "@/components/app/SectionHeader";
    import {addNavbarTab} from "@/components/app/js/globals";
    import UpdateCard from "@/components/app/UpdateCard";
    import {urlUpdates} from "@/components/app/js/globals";
    import {siteUrl} from "./app/js/globals";

    export default {
        props: {
            header: String,
            downloadPath: String, // "downloads/updates/", "downloads/updates/arc/"
            name: String //Updates, UpdatesArc
        },
        data: () => ({
            loading: true,
            arr: [],
        }),
        async beforeMount() {
            //console.log("#####",this.name)
            addNavbarTab(this,{name: this.name, route: '/'+this.name.toLowerCase()})

            const url = urlUpdates('download') //(process.env.NODE_ENV === 'production'?'http://talon74.ru':'http://localhost')+':9002/list'

            try {

                let res = await post(url, { downloadPath: this.downloadPath})
                this.arr=res.data
                for(let i=0; i<this.arr.length; i++){
                   this.arr[i].lazy=(i>10) //первые 10 вывести быстро
                   this.arr[i].sendingEmail=false
                   this.arr[i].sended=false
                   this.arr[i].downloaded=false
                }


            } catch (err) {
                console.log(err) //postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Medg#1' } )
            }


            this.loading=false;
        },
        components: {
            SectionHeader, UpdateCard
        }

    }
</script>

