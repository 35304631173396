<template>
    <!--src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"-->
    <section id="liner">
        <v-row no-gutters>
            <!--src="/img/home/home0.webp"-->
            <!--src="https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"-->
            <!--11 14 19 22 23 25 30! 37png-->
            <v-img
                    :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
                    :src="src0"
                    @click="$vuetify.goTo('#advantage', {duration:350,offset:0})"
            >
                <v-theme-provider dark>
                    <v-container   fill-height>
                        <v-row
                                align="start"
                                class="white--text mx-auto"
                                justify="center"
                        >
                            <v-col
                                    class="white--text text-center"
                                    cols="12"
                                    tag="h1"
                            >
                                <!--style="text-shadow:  2px 2px 1px rgba(0,0,0,0.4);"
                                <h1 class="font-weight-thin mb-4"
                                    :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                    style="text-shadow:  6px 1px 20px rgb(25,93,229);"

                                >

                                -->
                                <h1 class="font-weight-regular mb-4"
                                    :class="[$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4']"
                                    style="text-shadow:  6px 1px 20px rgb(25,93,229);"

                                >
                                    Лайнер
                                </h1>
                                <h4 :class="[$vuetify.breakpoint.smAndDown ? 'subtitle-2' : 'subtitle-1']"
                                    class="font-weight-bold mb4"
                                    style="text-shadow:  1px 1px 15px rgb(25,93,229);"
                                >
                                    ДОБРО ПОЖАЛОВАТЬ
                                </h4>

                            </v-col>

                            <v-btn
                                    class="align-self-end"
                                    fab
                                    outlined
                                    @click="$vuetify.goTo('#advantage', {duration:350,offset:0})"
                            >
                                <!--v-icon>mdi-chevron-double-down</v-icon-->
                                <v-icon>
                                    {{iconChevronDoubleDown}}
                                </v-icon>

                            </v-btn>
                        </v-row>
                    </v-container>
                </v-theme-provider>
            </v-img>

        </v-row>

    </section>

</template>

<script>
    import {
        mdiChevronDoubleDown
    } from '@mdi/js';

    export default {
        name: "HomeLiner",
        data: () => ({
            iconChevronDoubleDown: mdiChevronDoubleDown
        }),
        beforeMount(){
            console.log(this.$vuetify.application)
        },
        props: [
            'src0'
        ]
    }
</script>

<style scoped>

</style>