<template>
  <v-app>

    <div id="myNav" class="overlay">
      <a class="closebtn" @click="navClose()">&times;</a>
      <div class="overlay-content">
        <a @click="navContacts()">Контакты</a>
        <a @click="navMap()">Карта сайта</a>
        <a @click="navSend()">Напишите нам</a>
        <a @click="navUpdates()">Обновления</a>
      </div>
    </div>

    <SiteNavbar />

      <v-main>
          <router-view/>
      </v-main>

    <SiteFooter/>
  </v-app>

</template>

<style>
  div.main-content {
    top: 100%;
    position: absolute;
    width: 100%;
  }



  .overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-y: hidden;
    transition: 0.5s;
    font-size: 36px !important;
  }

  .overlay-content {
    position: relative;
    top: 30%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    font-size: 36px !important;
  }

  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
    color: grey !important;
    font-family: 'Lato', sans-serif !important;
  }

  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1 !important;
  }

  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }


  @media screen and (max-height: 450px) {
    .overlay {overflow-y: auto;}
    .overlay a {font-size: 20px}
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }
</style>

<script>
  import SiteFooter from '@/components/app/SiteFooter'
  import SiteNavbar from '@/components/app/SiteNavbar'
  import {closeNav, navContacts, navMap, navSend, navUpdates} from "./components/app/js/nav";
  //import {mdiAccountCancel, mdiAccountCheck} from "@mdi/js";

  export default {
    name: 'App' ,
    data: () => ({
            menu: [
                {
                    title: 'Закрыть меню',
                    icon: mdiClose
                },
                {
                    title: 'Контакты',
                    icon: mdiMapMarkerOutline
                },
                {
                    title: 'Карта сайта',
                    icon: mdiMapLegend
                },
                {
                    title: 'Напишите нам',
                    icon: mdiMessageAlertOutline
                },
                {
                    title: 'Главная',
                    icon: mdiHome
                },
                {
                    title: 'Обновления',
                    icon: mdiBriefcaseDownloadOutline
                },
                {
                    title: "Вход",
                    icon: mdiChevronRight
                }
            ]
    }),
    methods:{
      navClose(){
        closeNav()
      },
      async navUpdates(){
        await navUpdates(this)
      },
      async navSend(){
        await navSend(this)
      },
      async navContacts(){
        await navContacts(this)
      },
      async navMap(){
        await navMap(this)
      }

    },
    components: {
      SiteNavbar, SiteFooter
    }/*,
    created() {
      this.$store.dispatch('SET_APP', {
        path: '/'
      })ß
    },
    computed: {
      APP() {
        return this.$store.getters.APP;
      },

    }*/
  }

</script>
