<template>

    <!--v-lazy не ставить - не работает переход scroll по кнопки-->
    <section>
        <div class="pa-md-16">

            <SectionHeader :header="header"/>


            <div  class="md-16">
                <v-row>

                    <v-col
                            cols="12"
                            sm="4"
                            v-for="(e,i) in arr"
                            :key="i"
                    >


                        <v-card
                                class="ma-md-5"
                                max-width="700"
                                elevation="10"
                                rounded="xl"
                        >
                            <v-img
                                    :src="e.src"
                                    height="300px"
                            ></v-img>

                            <v-card-title>
                                {{e.header}}
                            </v-card-title>

                            <v-card-subtitle class="text-justify text-md-body-1" v-html="e.text"/>

                            <v-card-actions>
                                <v-btn
                                        color="orange lighten-2"
                                        text
                                        @click="e.reveal=!e.reveal"
                                >
                                    {{ e.reveal ? 'Свернуть' : 'Подробнее' }}
                                </v-btn>
                                <v-spacer></v-spacer>

                                <v-btn
                                        icon
                                        @click="e.reveal=!e.reveal"
                                >
                                    <v-icon>{{ e.reveal ? iconChevronUp : iconChevronDown }}</v-icon>
                                </v-btn>

                            </v-card-actions>

                            <v-expand-transition>
                                <div v-show="e.reveal">
                                    <v-divider/>
                                    <v-card-text class="text-justify text-md-body-1" v-html="e.fulltext"/>
                                </div>
                            </v-expand-transition>
                        </v-card>
                    </v-col>

                </v-row>
            </div>
        </div>
    </section>

</template>

<script>
    import SectionHeader from "./SectionHeader";
    import {
        mdiChevronUp,
        mdiChevronDown
    } from '@mdi/js';

    export default {
        name: "SectionCardShow",
        data: () => ({
            iconChevronUp: mdiChevronUp,
            iconChevronDown: mdiChevronDown
        }),
        components: {SectionHeader},
        props: {
            header:  String,
            arr:  Array
        }
    }
</script>

<style scoped>

</style>