<template>
        <section id="download" >
            <DownloadComponent
                    header="Загрузки"
                    downloadPath="downloads/download/"
                    v-bind:name="$options.name"
            />

        </section>

</template>

<script>
    import goTo from 'vuetify/lib/services/goto';
    import DownloadComponent from "@/components/DownloadComponent";



    export default {
        name: "Download",
        components: {
            DownloadComponent
        },
        props: {
            goto: {
                type: String,
                default: null
            }
        },
        mounted() {
            document.onreadystatechange = () => {
                if (document.readyState == "complete" && Boolean(this.goto)) {
                   this.gotoId(this.goto)
                   // this.$vuetify.goTo('#'+this.goto, {duration:350,offset:0})

                }
            }
            /* -- будет срабатывать два раза - незачем (но в safari оба метода не работают)
            window.addEventListener('load', () => {
                this.gotoId(this.goto)
                // run after everything is in-place
            })
            */
        },
        updated: function () {
            this.$nextTick(function () {
                this.gotoId(this.goto)
            })
        },
        methods: {
            gotoId(id){
                goTo('#'+id, {duration: 350, offset: 0})
                //console.log('gotoId>>>>>>')
            }
        }



    }
</script>

<style>
    /*
    #app {
        background: url('https://ohlaladani.com.br/wp-content/uploads/wallpaper-OHLALADANI_DESKTOP_WALLPAPERS_AVENTURA-2.jpg')
        no-repeat center center fixed !important;
        background-size: cover;
    }
    */
</style>
